import React from 'react';

import NavBar from './NavBar'

const Header = () => {
  return( 
  <div className="
  
  bg-eggplant md:bg-bgdrk
  h-20 text-jasmine
  flex items-center  ">
       
      <NavBar/>
     
  </div>
  
  
  
  );
};

export default Header;
